<template>
  <div class="support">
    <div class="container">
      <p class="text-center mt-4 mb-4">
        Please fill out form below and we will get back to you as soon as possible.
      </p>
      <van-form @submit="submitForm">
        <van-field
          v-model="formData.name"
          placeholder="Enter Name"
          :rules="[{ required: true, message: 'Name is required' }]"
        />
        <van-field
          v-model="formData.email"
          placeholder="Enter Email"
          :rules="[{ required: true, message: 'Email is required' }]"
        />
        <van-field
          v-model="formData.message"
          type="textarea"
          placeholder="Enter Message"
          :rules="[{ required: true, message: 'Message is required' }]"
        />

        <van-button color="#F54153" round block :loading="loading" loading-text="Submitting..." :disabled="loading" type="info" native-type="submit">Submit</van-button>

      </van-form>
    </div>
  </div>
</template>

<script>
import request from '../api/index'

export default {
  name: 'support',
  data () {
    return {
      loading: false,
      formData: {}
    }
  },
  methods: {
    async submitForm () {
      this.loading = true
      this.formData.access_key = '93a687f1-5697-4ffd-bb0c-8acc2a82ccb9'
      this.formData.subject = 'Influencer App Help'
      const response = await request({
        method: 'POST',
        url: 'https://api.web3forms.com/submit',
        data: this.formData
      })

      if (response.success) {
        this.$notify({ type: 'success', message: response.message })
        this.formData = {}
      } else {
        this.$notify({ type: 'danger', message: response.message })
      }
      this.loading = false
    }
  }
}
</script>

<style scoped>
  .van-form .van-cell {
    margin: 0 0 10px;
  }
</style>
